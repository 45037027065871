<template>
  <app-layout>
    <div class="px-4 px-sm-5 container">
      <async-object
        should-fetch
        :fetch-method="fetchData"
      >
        <template v-if="!transfer">
          <b-spinner></b-spinner>
        </template>

        <template v-else>
          <div v-if="transfer" class="heading d-flex justify-content-between mb-5">
            <h2>Transfert {{ transfer.transactionId }}</h2>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="mb-4">
                <router-link class="btn btn-link btn-sm" :to="{ name: 'transfers.index' }" active-class="active">
                  <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
                </router-link>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6">
              <div class="card mb-4">
                <div class="card-body">
                  <h4 class="header-title mb-4">Sommaire</h4>
                  <dl class="row">
                    <dt class="col-4">Date de paiement</dt>
                    <dd v-if="transfer.payedAt" class="col-8">{{ $longDate(transfer.payedAt) }}</dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-4">Commande</dt>
                    <dd v-if="transfer.merchantOrder && transfer.merchantOrder.order" class="col-8">{{ transfer.merchantOrder.order.reference }}</dd>
                  </dl>
                  <dl class="row">
                    <dt class="col-4">Montant payé</dt>
                    <dd class="col-8">{{ $money(transfer.payableAmount) }}</dd>
                  </dl>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="card mb-4">
                <div class="card-body">
                  <h4 class="header-title mb-4">COMPTE BANCAIRE</h4>
                  <p class="address" v-if="transfer.bankAccount">
                    {{ transfer.bankAccount.bank_name}}
                    <br>**** **** {{ transfer.bankAccount.last4 }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-body p-0">
                  <h4 class="header-title with-padding">Détail de la transaction</h4>

                  <div class="table-responsive">
                    <table class="table nowrap-table">
                      <thead>
                      <tr>
                        <th>Description</th>
                        <th class="col-100 text-center">Montant</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          Reversement commande <span v-if="transfer.merchantOrder && transfer.merchantOrder.order">{{ transfer.merchantOrder.order.reference }}</span>
                        </td>
                        <td class="text-center">
                          <span v-if="transfer.merchantOrder">{{ $money(transfer.merchantOrder.total) }}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Frais de plateforme
                        </td>
                        <td class="text-center">({{ $money(transfer.commissionTotal) }})</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Total payé</strong>
                        </td>
                        <td class="text-center"><strong>{{ $money(transfer.payableAmount) }}</strong></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <div class="card mb-4">
                <div class="card-body p-0">
                  <h4 class="header-title with-padding">Détail des frais</h4>

                  <div class="table-responsive">
                    <table class="table nowrap-table">
                      <thead>
                      <tr>
                        <th>Description</th>
                        <th class="col-100 text-center">Sous-total</th>
                        <th class="col-100 text-center" v-for="taxe in listTaxes" :key="taxe.key">{{ taxe.label }}</th>
                        <th class="col-100 text-center">Total</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td>
                          Commission sur vente <span class="pl-2 text-muted small commission-rate" v-if="commissionRate">
                          (<span>{{ $number(commissionRate) }}</span>)
                        </span>
                        </td>
                        <td class="text-center">{{ $money(transfer.commissionAmount) }}</td>
                        <td class="text-center" v-for="taxe in listTaxes" :key="taxe.key">{{ displayTaxes(taxe.key) }}</td>
                        <td class="text-center">{{ $money(transfer.commissionTotal) }}</td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <strong>Total</strong>
                        </td>
                        <td class="text-center"><strong>{{ $money(transfer.commissionTotal) }}</strong></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </async-object>
    </div>
  </app-layout>
</template>
<script>
import userData from '@/mixins/user-data'
import orderStatus from '@/mixins/orderStatus'
import formatsCurrency from '@/mixins/formatsCurrency'
import formatsDate from '@/mixins/formatsDate'

export default {
  mixins: [userData, orderStatus, formatsCurrency, formatsDate],
  computed: {
    commissionRate () {
      if (!this.transfer) {
        return null
      }

      return this.transfer.commissionRate
    },
    transfer () {
      return this.$store.getters['transfers/getDetailsById'](this.$route.params.id)
    },
    listTaxes () {
      const taxes = []

      Object.entries(this.transfer.commissionTaxes).forEach((entry) => {
        const [key] = entry
        const isAdded = taxes.find(x => x.key === key)

        if (!isAdded) {
          let label = ''

          switch (key) {
            case 'gst_hst':
              label = 'TPS/TVH'
              break
            case 'qst':
              label = 'TVQ'
              break
            default:
              label = ''
              break
          }

          taxes.push({ key, label })
        }
      })

      return taxes
    }
  },
  methods: {
    displayTaxes (taxKey) {
      if (this.transfer.commissionTaxes && this.transfer.commissionTaxes[taxKey]) {
        return this.$money(this.transfer.commissionTaxes[taxKey])
      }

      return ''
    },
    fetchData () {
      this.$store.dispatch('transfers/fetchById', {
        id: this.$route.params.id,
        storeId: this.currentStoreId
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  line-height: 24px;
}

.commission-rate {
  > span::after {
    content: '%';
    margin-left: 2px;
  }
}
</style>
